exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-client-portals-js": () => import("./../../../src/pages/client-portals.js" /* webpackChunkName: "component---src-pages-client-portals-js" */),
  "component---src-pages-financial-planner-reviews-js": () => import("./../../../src/pages/financial-planner-reviews.js" /* webpackChunkName: "component---src-pages-financial-planner-reviews-js" */),
  "component---src-pages-sanity-blogs-slug-current-js": () => import("./../../../src/pages/{SanityBlogs.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-blogs-slug-current-js" */),
  "component---src-pages-sanity-city-pages-slug-current-js": () => import("./../../../src/pages/{SanityCityPages.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-city-pages-slug-current-js" */),
  "component---src-pages-sanity-meet-the-team-slug-current-js": () => import("./../../../src/pages/{SanityMeetTheTeam.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-meet-the-team-slug-current-js" */),
  "component---src-pages-sanity-pages-slug-current-js": () => import("./../../../src/pages/{SanityPages.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-pages-slug-current-js" */),
  "component---src-pages-sanity-services-pages-slug-current-js": () => import("./../../../src/pages/{SanityServicesPages.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-services-pages-slug-current-js" */),
  "component---src-pages-wealth-management-blog-js": () => import("./../../../src/pages/wealth-management-blog.js" /* webpackChunkName: "component---src-pages-wealth-management-blog-js" */)
}

